.list-hover-slide {
    display: block;
    position: relative;
    z-index: 1;
    transition: .4s ease color;
}
.list-hover-slide:before {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    left: -150%;
    top: 0;
    width: 120%;
    height: 100%;
    border-right: solid 5px #DF4500;
    background: rgba(0, 0, 0, 0.3);
    transition: .4s ease left;
}
.list-hover-slide.is-current:before, .list-hover-slide:hover:before {
    left: -20%;
}

.list-hover-slide div:nth-child(1), .list-hover-slide div:nth-child(2) {
    transition: .4s ease all;
}

.list-hover-slide div:nth-child(2) > p {
    transition: .4s ease all;
}

.short-sidebar-scale-down div div:nth-child(1) {
    transform: scale(.7, .7) translateY(-12px);
}

.short-sidebar-scale-down div div:nth-child(2) {
    position: absolute;
    bottom: 2px;
    width: 100%;
}

.short-sidebar-scale-down div div:nth-child(2) > p {
    font-size: 10px;
    margin: 0 auto;
    margin-bottom: 5px;
}
