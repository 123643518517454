.rotate-icon-change-old-icon {
    transition: opacity 0.4s, transform 0.4s;
    -webkit-transition: opacity 0.4s, transform 0.4s;
}

.rotate-icon-change-new-icon {
    transition: opacity 0.4s, transform 0.4s;
    transform: rotate(-180deg) scale(0.5);
    opacity: 0;
    -webkit-transition: opacity 0.4s, transform 0.4s;
    -webkit-transform: rotate(-180deg) scale(0.5);
    -webkit-opacity: 0;
}

/* .menu-icon-first {

} */

.menu-icon-second {
    margin-left: -18px;
}

.menu-icon-third {
    margin-left: -18px;
}